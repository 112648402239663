import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SocialConnect from "../components/ConnectSocial";


const About = ({ data }) => (
  <Layout title={"Terms and Conditions"}>
    <div className="about">
      <div
        className="hero2"
      >
        <h1>Terms & Conditions</h1>
      </div>

      <div className="ssbody wrapper_max">
        <div className="content">
          <div
            className="page-body"
            dangerouslySetInnerHTML={{ __html: data.strapiTermsAndConditions.body }}
          />

          <SocialConnect />
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  {
    strapiTermsAndConditions {
      body
    }
  }
`;

export default About;
